import React from 'react'
import { Link } from 'gatsby'

export default () => (
  <>
    <div id="client">Zoe Bruns</div>
    <div className="slideshow password error-404">
      <h1>
        <div>Error 404</div>
        <div>The requested content could not be found.</div>
      </h1>
      <form><Link className="input" to="/">Go Home</Link></form>
    </div>
    <Link className="index-link" to={'/'}>
      <span>Return to 'Selected Work'</span>
    </Link>
  </>
)
